import Flex from "@components/elements/flex"
import Span from "@components/elements/span"
import React, { useCallback } from "react"
import styled, { useTheme } from "styled-components"
import PropTypes from "prop-types"

import { ReactComponent as KlipsIconLogo } from "@images/comparison/klips-icon.svg"
import { ReactComponent as PowerMetricsIconLogo } from "@images/comparison/powermetrics-icon.svg"
import Div from "@components/elements/div"
import { navigate } from "gatsby"

const StyledSelector = styled(Flex)`
  @media only screen and (max-width: 1200px) {
    margin: 0 auto;
  }
`

export const ProductSelector = ({ klips, setKlips, active }) => {
  const { color, shadow } = useTheme()

  const handlePmClick = useCallback(() => {
    setKlips(false)
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete("product")
    navigate(`?${urlParams.toString()}`, { replace: true })
  }, [])

  const handleKlipsClick = useCallback(() => {
    setKlips(true)
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set("product", "klips")
    navigate(`?${urlParams.toString()}`, { replace: true })
  }, [])

  return (
    <StyledSelector
      boxShadow={shadow.soft}
      borderRadius={"1rem"}
      background="white"
      width="fit-content"
      flexFlow="column"
      alignSelf="flex-start"
      padding="1rem"
      position="relative"
      zIndex={1}
    >
      <Span
        margin="0 0 .25rem"
        fontWeight={600}
        fontSize="0.9rem"
        opacity="0.5"
      >
        Products:
      </Span>
      <Div
        borderRadius="1rem"
        padding="0.5rem 0"
        background={!klips && active ? color.indigo100 : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        onClick={handlePmClick}
      >
        <Flex
          flexFlow="row"
          gap="0.5rem"
          padding="0 1rem"
          alignItems="center"
          alignSelf="center"
        >
          <PowerMetricsIconLogo height="16px" />
          <Span fontSize="0.9rem" color="inherit" fontWeight={700}>
            PowerMetrics
          </Span>
        </Flex>
      </Div>
      <Div
        borderRadius="1rem"
        padding="0.5rem 0"
        background={klips && active ? color.blue100 : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        onClick={handleKlipsClick}
      >
        <Flex
          padding="0 1rem"
          flexFlow="row"
          gap="0.5rem"
          alignItems="center"
          alignSelf="center"
        >
          <KlipsIconLogo height="16px" />
          <Span fontSize="0.9rem" color="inherit" fontWeight={700}>
            Klips
          </Span>
        </Flex>
      </Div>
    </StyledSelector>
  )
}

ProductSelector.propTypes = {
  klips: PropTypes.bool.isRequired,
  setKlips: PropTypes.func.isRequired,
  active: PropTypes.bool,
}

export const KlipsSelector = ({ agency, setAgency, ...rest }) => {
  const { color } = useTheme()

  const handleAgencyClick = useCallback(() => {
    setAgency(true)
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set("type", "agency")
    navigate(`?${urlParams.toString()}`, { replace: true })
  }, [])

  const handleBusinessClick = useCallback(() => {
    setAgency(false)
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete("type")
    navigate(`?${urlParams.toString()}`, { replace: true })
  }, [])

  return (
    <Flex
      borderRadius={"1rem 1rem 0 0"}
      background="white"
      overflow="hidden"
      width="100%"
      flexFlow="row"
      alignSelf="center"
      {...rest}
    >
      <Div
        background={!agency ? color.blue100 : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        width="50%"
        color={color.blue500}
        padding="0.5rem 0"
        onClick={handleBusinessClick}
        center
      >
        <Span color={color.indigo600} fontWeight={!agency ? "700" : "600"}>
          Plans For Businesses
        </Span>
      </Div>
      <Div
        background={agency ? color.blue100 : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        width="50%"
        center
        color={color.indigo400}
        padding="0.5rem 0"
        onClick={handleAgencyClick}
      >
        <Span color={color.indigo600} fontWeight={agency ? "700" : "600"}>
          Plans For Agencies
        </Span>
      </Div>
    </Flex>
  )
}

KlipsSelector.propTypes = {
  agency: PropTypes.bool.isRequired,
  setAgency: PropTypes.func.isRequired,
  rest: PropTypes.object,
}

export const Selector = ({
  klips,
  monthly,
  handleMonthlyClick,
  handleYearlyClick,
  ...rest
}) => {
  const { color, shadow } = useTheme()
  const backgroundColor = klips ? color.blue100 : color.indigo100

  return (
    <Flex
      boxShadow={shadow.soft}
      padding="0.5rem"
      borderRadius={"3rem"}
      background="white"
      width="min-content"
      flexFlow="row"
      alignSelf="center"
      {...rest}
    >
      <Div
        borderRadius="2rem"
        background={monthly ? backgroundColor : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        padding="0.1rem 0.75rem"
        onClick={handleMonthlyClick}
      >
        <Span fontSize="0.9rem" color={color.indigo600} fontWeight={700}>
          Monthly
        </Span>
      </Div>
      <Div
        borderRadius="2rem"
        background={!monthly ? backgroundColor : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        padding="0.1rem 0.75rem"
        onClick={handleYearlyClick}
      >
        <Span fontSize="0.9rem" color={color.indigo600} fontWeight={700}>
          Annual
        </Span>
      </Div>
    </Flex>
  )
}

Selector.propTypes = {
  klips: PropTypes.bool,
  monthly: PropTypes.bool.isRequired,
  handleMonthlyClick: PropTypes.func.isRequired,
  handleYearlyClick: PropTypes.func.isRequired,
  toggled: PropTypes.bool,
}
