import React, { useCallback, useState } from "react"
import Layout from "@components/layout"
import { PricingIcons } from "@components/pages/pricing/pricing-icons"
import Div from "@components/elements/div"
import Heading from "@components/elements/heading"
import Flex from "@components/elements/flex"
import Button from "@components/elements/button"
import Container from "@components/layout/container"
import { HighGradient } from "@components/common/gradient"
import { useGradient } from "@hooks/use-gradient"
import { PowerMetricsEOPCta } from "@components/cta/eop/powermetrics"
import { GetADemoForm } from "@components/forms/get-a-demo"
import { useTheme } from "styled-components"
import { PowerMetricsTable } from "@components/pages/pricing/powermetrics/table"

const MicrositePricingPage = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleClick = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  const [monthly, setMonthly] = useState(true)
  
  const handleMonthlyClick = useCallback(() => {
    setMonthly(true)
  }, [])

  const handleYearlyClick = useCallback(() => {
    setMonthly(false)
  }, [])

  const { color } = useTheme()
  const { gradient } = useGradient()

  return (
    <Layout
      title={`PowerMetrics pricing plans, add-ons and services`}
      description={`Analytics designed for business teams with pricing that works for you. Customize your plan and get started quickly with PowerMetrics Professional Services.`}
      fullWidth
      marginless
      microsite
    >
      <GetADemoForm modalOpen={modalOpen} setModalOpen={setModalOpen} />

      <Div
        container
        margin="8rem auto 0"
        position="relative"
      >
        <HighGradient src={gradient.cdn} loading="eager" />
        <Flex center margin="0 auto 6rem" alignItems="center">
          <Container center text>
            <header>
              <Heading fontSize="2.8rem" lineHeight="120%" margin="2rem 0">
                Analytics designed for business teams with pricing that works
                for you.
              </Heading>
            </header>
            <Button.PowerMetrics data-cta-location="header" noticeColor={color.indigo600}>Get Started Free</Button.PowerMetrics>
          </Container>
        </Flex>
      </Div>

      <PowerMetricsTable
        handleClick={handleClick}
        monthly={monthly}
        handleMonthlyClick={handleMonthlyClick}
        handleYearlyClick={handleYearlyClick}
        microsite
      />

      <PowerMetricsEOPCta
        microsite
        heading={"Make analytics everybody's business."}
        description={"Get started with a free account."}
      />

      <PricingIcons />
    </Layout>
  )
}

export default MicrositePricingPage
